$(document).ready(function() {
  // $('.sidebar-disabled').on(
  //   'click',
  //   function() {
  //     $(this).removeClass('sidebar-disabled').addClass('sidebar-active');
  //     $(".sidebar-active").removeClass('sidebar-active').addClass('sidebar-disabled');
  //     // $(this).removeClass('sidebar-disabled').addClass('sidebar-active');
  //     // $(".sidebar-active").toggleClass('sidebar-active sidebar-disabled');
  //     // $(".sidebar-active").remove();
  //   }
  // );
});

// nomenclatures

window.showCategoryNames = function(event) {
  var rowIndex = $(event)
    .closest("tr")
    .prevAll().length;
  console.log($(event).val());
  $.ajax({
    url: "/nomenclatures/name/" + $(event).val(),
    dataType: "script"
  });
};

window.updateNomenclaturesIndexes = function() {
  console.log("UPDINDX");
  $(".nomenclature").each(function(index) {
    $(this).attr("name", `selection[specification][${index}][code]`);
  });
  $(".amount").each(function(index) {
    $(this).attr("name", `selection[specification][${index}][amount]`);
  });
};

// unloadings

// window.startFilling = function(event) {
//   $.post($(event).data("url")).done(function(data) {
//     getProgress(data.next, 2000);
//   });
//   $("#spinner").removeClass("invisible");

//   $("#unloadings").remove();
// };

// window.getProgress = function(progress_url, poll_interval) {
//   console.log('getProgress');
//   $.get(progress_url).done(function(progress) {
//     if(progress.html) {
//       console.log('HTML');
//       // job is complete; show HTML returned by server
//       $("#spinner").html(progress.html);
//     } else {
//       console.log('TIMEOUT');
//       // job is not yet complete, try again later at the URL
//       // provided by the server
//       setTimeout(function() {
//         getProgress(progress.next, poll_interval);
//       }, poll_interval);
//     }
//   });
// };

window.startFilling = function(event) {};
